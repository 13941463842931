html,
body {
  margin: 0;
  padding: 0;
}
.App {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
}

.title-text {
  font-family: "JetBrains Mono", monospace;
  font-size: 4rem;
  font-weight: bold;
  color: white;
  text-shadow: 2px 2px 8px rgba(8, 16, 32, 0.8);
  cursor: pointer;
  transition: all 0.3s ease;
}

.title-text:hover {
  text-shadow: 2px 2px 8px rgba(170, 197, 255, 0.8);
  transform: scale(1.1);
}

.fade-text {
  transition: opacity 1.5s;
}

.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}
#next-screen {
  height: 120vh;
  display: flex;
  flex-direction: column;
  align-items: center; /* Add this line to center the content horizontally */
  justify-content: center; /* Add this line to center the content vertically */
}

#next-screen h2 {
  font-family: "Montserrat", sans-serif;
  color: black;
  font-size: 2rem;
  font-weight: bold;
}

.logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@font-face {
  font-family: 'JetBrains Mono';
  src: url('/fonts/JetBrainsMono-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


.text {
  font-family: "JetBrains Mono", monospace;
  font-size: 1.5rem;
}
.grey-section {
  width: 70vw; /* Set the width to 100% of the viewport width */
  height: 20vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0; /* Add padding to the top and bottom for spacing */
  margin-top: 70px;
}

.footer-text {
  font-family: "JetBrains Mono", monospace;
  font-size: 0.77rem;
}
.blockbluff-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.text-small {
  font-family: "JetBrains Mono", monospace;
  font-size: 1rem;
}

.block-text {
  font-family: "JetBrains Mono", monospace;
  font-size: 1.5rem;
  color: rgb(114, 114, 114);
}

.work-with-us-btn {
  font-family: "JetBrains Mono", monospace;
  font-size: 1.5rem;
  background-color: #123456;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 100px;
  transition: all 0.3s ease;
}

.work-with-us-btn:hover {
  background-color: #1a4a7f;
}
